@import 'webresources-sources/scss/imports';

.search-input-field {
  position: relative;

  .svg-icon {
    position: absolute;
    left: 0;
    width: 40px;
    z-index: 2;
    height: 40px;
    padding: 10px;
    display: flex;
  }

  input {
    border: 1px solid #708392;
    padding-left: 40px;
    height: 40px;

    /* https://css-tricks.com/focus-visible-and-backwards-compatibility/ */
    &:focus {
      border: 1px solid #708392;
      outline: none;
      box-shadow: $box-shadow-ring;
      transition: $transition-focus-ring;

      &:not(:focus-visible) {
        box-shadow: none;
      }
    }

    &:focus-visible {
      outline: none;
      box-shadow: $box-shadow-ring;
    }
  }
}
