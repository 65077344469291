/* bootstrap overwrite */
.search-input-field {
  position: relative;
}
.search-input-field .svg-icon {
  position: absolute;
  left: 0;
  width: 40px;
  z-index: 2;
  height: 40px;
  padding: 10px;
  display: flex;
}
.search-input-field input {
  border: 1px solid #708392;
  padding-left: 40px;
  height: 40px;
  /* https://css-tricks.com/focus-visible-and-backwards-compatibility/ */
}
.search-input-field input:focus {
  border: 1px solid #708392;
  outline: none;
  box-shadow: 0 0 0 2px var(--focus-offset-color, #fff), 0 0 0 calc(2px + 2px) var(--focus-color, #c9d1e3);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.search-input-field input:focus:not(:focus-visible) {
  box-shadow: none;
}
.search-input-field input:focus-visible {
  outline: none;
  box-shadow: 0 0 0 2px var(--focus-offset-color, #fff), 0 0 0 calc(2px + 2px) var(--focus-color, #c9d1e3);
}